.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.sea-index {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
  font-size: 0.28rem;
  color: #333;
}
.sea-index .login-status {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.sea-index .content {
  padding-top: 4rem;
}
.sea-index .content .entry {
  margin: 0 0.2rem 0.2rem;
}
.sea-index .content .entry img {
  width: 100%;
}
.sea-index .login-entrys {
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}
